import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, query, orderBy, limit } from "firebase/firestore/lite";

const RecipesPage = () => {
  const [recipes, setRecipes] = useState([]);
  const firestoreDatabase = getFirestore();

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const recipesCollection = collection(firestoreDatabase, "recipes");
        const q = query(recipesCollection, orderBy("timestamp", "desc"), limit(30));
        const recipesSnapshot = await getDocs(q);
        const recipesData = [];
        recipesSnapshot.forEach((doc) => {
          recipesData.push({ id: doc.id, ...doc.data() });
        });
        // Sort recipes by title
        setRecipes(recipesData);
      } catch (error) {
        console.error("Error fetching recipes:", error);
      }
    };

    fetchRecipes();
  }, [firestoreDatabase]);

  return (
    <div className="text-center mx-auto mt-10">
      <ul>
        {recipes.map((recipe) => (
          <li key={recipe.id} className="mb-2">
            <Link to={`/recipe/${recipe.id}`}>{recipe.searchTerm}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecipesPage;
