import React from "react";
import ReactMarkdown from 'react-markdown';

const StepByStepProcess = ({ steps }) => {
  const renderers = {
    a: ({ href, children }) => (
      <a href={href} className="text-blue-500 hover:underline" target="_blank">{children}</a>
    ),
  };

  return (
    <ul>
      {steps.map((step, index) => (
        <li
          key={index}
          className="py-6 my-7 bg-white px-6 py-3 rounded-2xl border"
        >
          <div className="text-center block font-semibold text-black mb-3">
            {index + 1}. {step.title}
          </div>
          <ReactMarkdown components={renderers}>
            {step.description}
          </ReactMarkdown>
        </li>
      ))}
    </ul>
  );
};

export default StepByStepProcess;