import React, { useState } from "react";
import { MdEmail, MdCheckCircle } from "react-icons/md";

const SendEmailForm = ({ subject, projectURL }) => {
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSending(true);
    try {
      const response = await fetch("https://s5xadp.buildship.run/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ toAddress: email, subject, projectURL }),
      });
      if (response.ok) {
        // Handle success
        console.log("Email sent successfully");
        setEmailSent(true);
      } else {
        // Handle error
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Failed to send email:", error);
    } finally {
      setIsSending(false);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-center space-y-4">
      {!isSending && !emailSent && (
        <label className="text-center relative">
          <span className="">Send me my recipe:</span>
          <div className="relative inline-block ml-3">
            <input
              type="email"
              value={email}
              onChange={handleChange}
              className="block mx-auto px-4 py-2 border rounded pr-12" // Increased the padding on the right
              placeholder="your email address"
            />
            <button
              type="submit"
              className="absolute top-0 right-0 bg-blue-500 text-white px-3 py-2 rounded text-xl"
              style={{ height: 'calc(100% - 1px)' }} // Adjusted height to match the input field
            >
              <MdEmail />
            </button>
          </div>
        </label>
      )}
      {isSending && (
        <div className="flex items-center text-gray-500">
          Sending email...
        </div>
      )}
      {emailSent && (
        <div className="flex items-center text-green-500">
          <MdCheckCircle className="mr-2" />
          Email sent successfully
        </div>
      )}
    </form>
  );
};

export default SendEmailForm;
