import React from "react";
import { motion } from "framer-motion";

const Icon = ({
  icon,
  index,
  showTooltip,
  hideTooltip,
  isVibrating,
  isSpinning,
}) => {
  const variants = {
    vibrating: {
      rotate: [-3, 0, 3, 0],
      transition: { duration: 0.2, repeat: Infinity, ease: "linear" },
    },
    spinning: {
      rotate: [0, 360],
      transition: { duration: 1.1, repeat: Infinity, ease: "linear" },
    },
    reset: {
      rotate: 0,
      transition: { duration: 0 },
    },
  };

  return (
    <motion.div
      key={icon.name}
      className={`flex flex-col items-center space-y-1 m-2 px-3`}
      onMouseEnter={() => {
        showTooltip(index);
      }}
      onMouseLeave={() => {
        hideTooltip(index);
      }}
      animate={isVibrating ? "vibrating" : isSpinning ? "spinning" : "reset"}
      variants={variants}
      style={{ originY: 0.38 }}
    >
      <div className="m-0.5 bg-blue-100 p-3 rounded-full transform transition-transform duration-500 hover:-translate-y-1">
        <img
          src={icon.imageUrl}
          className="h-12 w-12 rounded-full"
          alt={icon.name}
        />
      </div>
      <div className="text-sm text-gray-600">{icon.name}</div>
      <span
        id={`tooltip-${index}`}
        className="absolute z-10 hidden py-3 px-5 text-s border bg-white rounded-lg shadow-sm translate-y-[-100%] -mt-10 w-48"
      >
        {icon.description}
      </span>
    </motion.div>
  );
};

export default Icon;