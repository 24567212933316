import React from "react";
import Icon from "./Icon";

const IconList = ({
  icons,
  showTooltip,
  hideTooltip,
  isVibrating,
  isSpinning,
}) => {
  return (
    <div className="flex flex-wrap justify-center mt-12 max-w-screen-md">
      {icons.map((icon, index) => {
        return (
          <Icon
            key={index}
            icon={icon}
            index={index}
            showTooltip={showTooltip}
            hideTooltip={hideTooltip}
            isVibrating={isVibrating}
            isSpinning={isSpinning}
          />
        );
      })}
    </div>
  );
};

export default IconList;
