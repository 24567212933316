import React, { useState, useEffect } from "react";
import { FaHammer } from "react-icons/fa";
import { motion } from "framer-motion";

const SearchBar = ({ searchTerm, setSearchTerm, handleSearch, showSubmit, readOnly }) => {
  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  useEffect(() => {
    const placeholderTimer = setInterval(() => {
      setPlaceholderIndex(
        (prevIndex) => (prevIndex + 1) % placeholderTexts.length
      );
    }, 5000); // Change placeholder every 5 seconds

    return () => clearInterval(placeholderTimer);
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const dynamicWidth = window.innerWidth < 700 ? "100%" : Math.min(
    Math.max(400, searchTerm.length * 10 + 160),
    640
  ) + "px";

  const placeholderTexts = [
    "A dating app for chess players",
    "An app for pet lovers",
    "A cooking app for indecisive chefs",
    "A language learning community",
  ];

  return (
    <div className="relative">
      <input
        type="text"
        placeholder={placeholderTexts[placeholderIndex]}
        style={{ width: dynamicWidth }}
        className="py-3 px-4 text-xl bg-white rounded-full border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-200"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        onKeyPress={handleKeyPress}
        readOnly={readOnly}
        disabled={readOnly}
      />
      {showSubmit ? (
        <button
          className="absolute right-0 top-0 h-full px-4 text-blue-500 hover:text-blue-700 focus:outline-none"
          onClick={handleSearch}
        >
          <FaHammer className="text-2xl" />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchBar;