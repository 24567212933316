import React, { useState } from 'react'

const icons = [
    { 
        name: 'Bubble', 
        description: 'A no-code platform for building web applications', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1019535638155030529/khKE2TZC_400x400.jpg' 
    },
    { 
        name: 'Adalo', 
        description: 'A no-code platform for building mobile applications', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1146051540802818049/OT6-JUhc_400x400.png' 
    },
    { 
        name: 'FlutterFlow', 
        description: 'A no-code platform for building web and mobile applications', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1625151278535016448/tML8CA1M_400x400.jpg' 
    },
    { 
        name: 'Webflow', 
        description: 'A visual web design tool for building responsive websites', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1709992260694380544/IpabLWL7_400x400.jpg' 
    },
    { 
        name: 'AppSheet', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1323705166063828992/hF8RGRiq_400x400.jpg' 
    },
    { 
        name: 'Glide', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1438645790755409921/MFD7tRvP_400x400.jpg' 
    },
    { 
        name: 'OutSystems', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1664347590278692869/i_VT8oaA_400x400.jpg' 
    },
    { 
        name: 'Appian', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1376559245240451080/0QCgMoAF_400x400.jpg' 
    },
    { 
        name: 'Shopify', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1730319820238049280/FYF6hwoK_400x400.jpg' 
    },
    { 
        name: 'Wix', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1671515831044390912/s3qqjYY7_400x400.jpg' 
    },
    { 
        name: 'Carrd', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1235640439786663937/wugr3yK0_400x400.jpg' 
    },
    { 
        name: 'Retool', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1352777057311223808/QxBjImFN_400x400.jpg' 
    },
    { 
        name: 'Thunkable', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1090362497800925184/K7nLalAU_400x400.jpg' 
    },
    { 
        name: 'Airtable', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/913790926950096896/xt2OyAdt_400x400.jpg' 
    },
    { 
        name: 'Teachable', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1699827899375972352/4yWnV9hM_400x400.jpg' 
    },
    { 
        name: 'Thinkific', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets',
        imageUrl: 'https://pbs.twimg.com/profile_images/1742968241029537793/Qs6Z0NlJ_400x400.jpg' 
    },
    { 
        name: 'Zapworks', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1666065398997852161/EJ1tn_Sj_400x400.jpg' 
    },
    { 
        name: 'Zapier', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1623031323849011201/APPk5y29_400x400.png' 
    },
    { 
        name: 'Typeform', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1425364859194052615/vXfQSaQ9_400x400.png' 
    },
    { 
        name: 'Trafft', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1392098220789145605/fLocXnMi_400x400.jpg' 
    },
    { 
        name: 'ShareTribe', 
        description: 'A no-code platform for building mobile and web applications that integrate with Google Sheets', 
        imageUrl: 'https://pbs.twimg.com/profile_images/1747573996684533761/DZWGJG3R_400x400.jpg' 
    }
];

export default icons;