import React, { useState, useEffect } from "react";
import SearchBar from "./components/SearchBar";
import IconList from "./components/IconList";
import RecipesPage from "./RecipesPage";
import SendEmailForm from "./components/SendEmailForm";

import StepByStepProcess from "./components/StepByStepProcess";
import icons from "./components/icons";
import { PiCookingPot } from "react-icons/pi";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
} from "firebase/firestore/lite";
import {
  useNavigate,
  Link,
  useParams,
  Routes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom"; // Import useHistory from React Router

const firebaseConfig = {
  apiKey: "AIzaSyAvcizH0oy3UtBGZlHoGmMUKlpkGKDDZek",
  authDomain: "lowcoderecipes.firebaseapp.com",
  projectId: "lowcoderecipes",
  storageBucket: "lowcoderecipes.appspot.com",
  messagingSenderId: "527197244839",
  appId: "1:527197244839:web:96c4b3b0e164d625fb3a82",
};

const firebaseApp = initializeApp(firebaseConfig);
const firestoreDatabase = getFirestore(firebaseApp);

export function Component({ recipes }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [isVibrating, setIsVibrating] = useState(false);
  const [lastSearchTerm, setLastSearchTerm] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [steps, setSteps] = useState([]); // State to store search results
  const navigate = useNavigate();

  const { recipeId } = useParams();
  const [recipe, setRecipe] = useState(null);

  useEffect(() => {
    const fetchRecipe = async () => {
      setRecipe(null);
      try {
        const recipeDoc = doc(firestoreDatabase, "recipes", recipeId);
        const recipeSnapshot = await getDoc(recipeDoc);
        if (recipeSnapshot.exists()) {
          setRecipe(recipeSnapshot.data());
          setSearchTerm(recipeSnapshot.data().searchTerm);
        } else {
          console.log("Recipe not found");
        }
      } catch (error) {
        console.error("Error fetching recipe:", error);
      }
    };

    fetchRecipe();
  }, [firestoreDatabase, recipeId]);

  useEffect(() => {
    document.title = "Low Code Recipes: Build any app with low code";
  }, []);

  const showTooltip = (index) => {
    const tooltip = document.getElementById(`tooltip-${index}`);
    if (tooltip && !isVibrating && !isLoading) {
      tooltip.classList.remove("hidden");
    }
  };

  const hideTooltip = (index) => {
    const tooltip = document.getElementById(`tooltip-${index}`);
    if (tooltip) {
      tooltip.classList.add("hidden");
    }
  };

  const handleChange = (searchTerm) => {
    if (searchTerm !== lastSearchTerm) {
      setIsVibrating(true);
    } else {
      setIsVibrating(false);
    }
    setSearchTerm(searchTerm);
  };

  const handleSearch = async () => {
    if (searchTerm.trim() === "") {
      setShowSearchResults(false);
      setIsVibrating(false);
    } else {
      setLastSearchTerm(searchTerm);
      setShowSearchResults(false);
      setIsVibrating(false);
      setIsLoading(true); // Show loading indicator

      try {
        // Make API call to fetch search results
        const response = await fetch(
          `https://s5xadp.buildship.run/recipe?query=${searchTerm}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        // Update steps state with search results
        setSteps(data.steps);
        setShowSearchResults(true);

        // Save data to Firebase
        const docRef = await addDoc(collection(firestoreDatabase, "recipes"), {
          searchTerm: searchTerm,
          steps: data.steps,
          timestamp: new Date(),
        });

        // Redirect to the new document's URL
        navigate(`/recipe/${docRef.id}`);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error: display error message or retry logic
      } finally {
        setIsLoading(false); // Hide loading indicator
      }
    }
  };

  const resetSearch = () => {
    setSearchTerm("");
    setShowSearchResults(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 pb-20 pt-16">
      <Link to="/">
        <h2 className="text-2xl font-semibold mb-4">
          What would you like to build?
        </h2>
      </Link>
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={handleChange}
        handleSearch={handleSearch}
        showSubmit={isVibrating}
        resetSearch={resetSearch}
        readOnly={isLoading}
      />
      <div class="flex items-center mt-5">
        <input
          type="checkbox"
          id="toggle"
          checked="checked"
          class="mr-2 cursor-not-allowed opacity-50"
        />
        <label for="toggle" class="select-none">
          Make it public
        </label>
        <p className="ml-10">
          <Link to="/recipes">
            <PiCookingPot className="inline mr-1.5 mb-1" />
            Public recipes
          </Link>
        </p>
      </div>
      {recipeId && !isLoading ? (
        recipe ? <>
          <div className="mt-12 max-w-[730px] m-5 text-md">
            <h1 className="text-center text-xl mb-6 font-semibold">
              {recipe ? recipe.searchTerm : lastSearchTerm}
            </h1>
            <SendEmailForm subject={recipe.searchTerm} projectURL={window.location.href} />
            <StepByStepProcess steps={recipe ? recipe.steps : steps} />
          </div>
        </> : <p className="p-20">Loading...</p>
      ) : recipes && !isLoading ? (
        <RecipesPage />
      ) : (
        <IconList
          icons={icons}
          showTooltip={showTooltip}
          hideTooltip={hideTooltip}
          isVibrating={isVibrating}
          isSpinning={isLoading}
        />
      )}
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/recipe/:recipeId" element={<Component />} />
        <Route path="/" element={<Component />} />
        <Route path="/recipes" element={<Component recipes={true} />} />
      </Routes>
    </Router>
  );
}
